@charset "UTF-8";
.preview[data-v-87036cec] {
  width: 100%;
}
.previewTitle[data-v-87036cec] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

/* input为number时去掉对应的上下箭头 */
[data-v-87036cec] input::-webkit-outer-spin-button,[data-v-87036cec] input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
[data-v-87036cec] input[type=number] {
  -moz-appearance: textfield;
}